<template lang="pug">
div
  el-form.pl-20.mt-10(inline)
    el-form-item(label="TARİH")
      el-date-picker(
        v-model="filters.date"
        type="date"
        format="dd.MM.yyyy"
        value-format="yyyy-MM-dd"
        :picker-options="{ firstDayOfWeek: 1 }"
        style="width: 145px"
        @change="fetchAll"
        clearable
        placeholder="Tarih"
      )
  el-divider(content-position="left" v-if="can('sales.create')")
    span.text-primary KASA TOPLAMI
    small.ml-10 {{ filters.date|dayjs('D MMMM YYYY') }}

  el-alert(
    type="info"
    title="Satış kaydı yok"
    center
    show-icon
    v-if="can('sales.create') && fetchingTotals === false && totals.length === 0"
    :closable="false"
  )
  grid.pb-5(
    v-loading="fetchingTotals"
    v-if="can('sales.create')"
    :clickable="false"
    :column-num="totals.length"
    direction="horizontal"
    icon-size="18px"
    :gutter="5"
  )
    grid-item(
      v-for="(t, i) in totals"
      :key="i"
    )
      span.pl-10(slot="text")
        strong {{ t.currency }} &bullet;
        tag.ml-5(:type="t.amount > 0 ? 'success' : 'danger'") {{ t.amount }}
        br
        span.text-muted.text-success {{ t.type.name }}
  el-divider(content-position="left" v-if="can('sales.create')")
    span.text-primary GİRİŞLER
    small.ml-10 {{ filters.date|dayjs('D MMMM YYYY') }}
  el-divider(content-position="left" v-if="can('sales.create')")
    span.text-primary ÜRÜN SATIŞLARI
    small.ml-10 {{ filters.date|dayjs('D MMMM YYYY') }}
  el-divider(content-position="left" v-if="can('sales.create')")
    span.text-success SATICI PERFORMANSI
    small.ml-10 {{ filters.date|dayjs('D MMMM YYYY') }}
  el-divider(content-position="left" v-if="can('sales.create')")
    span.text-danger GİDERLER
    small.ml-10 {{ filters.date|dayjs('D MMMM YYYY') }}
</template>
<script>
import { Grid, GridItem, Tag } from 'vant'
//import { Chart, registerInteraction } from '@antv/g2'

export default {
  name: 'dashboard',
  components: {
    Grid,
    GridItem,
    Tag
  },
  data() {
    return {
      filters: {
        date: window.dayjs().format('YYYY-MM-DD')
      },
      totals: []
    }
  },
  created() {
    this.fetchAll()
    /*
    this.$nextTick(() => {
      registerInteraction('element-link', {
        start: [
          {trigger: 'interval:mouseenter', action: 'element-link-by-color:link'}
        ],
        end: [
          {trigger: 'interval:mouseleave', action: 'element-link-by-color:unlink'}
        ]
      });

      const data = [
        { year: '2014', type: 'Sales', sales: 1000 },
        { year: '2015', type: 'Sales', sales: 1170 },
        { year: '2016', type: 'Sales', sales: 660 },
        { year: '2017', type: 'Sales', sales: 1030 },
        { year: '2014', type: 'Expenses', sales: 400 },
        { year: '2015', type: 'Expenses', sales: 460 },
        { year: '2016', type: 'Expenses', sales: 1120 },
        { year: '2017', type: 'Expenses', sales: 540 },
        { year: '2014', type: 'Profit', sales: 300 },
        { year: '2015', type: 'Profit', sales: 300 },
        { year: '2016', type: 'Profit', sales: 300 },
        { year: '2017', type: 'Profit', sales: 350 }
      ]
    
      const chart = new Chart({
        container: this.$refs.container,
        autoFit: true,
        height: 500,
      });

      chart.data(data);
      chart.scale({
        sales: {
          max: 2400,
          tickInterval: 600,
          nice: true,
        },
      });

      chart.tooltip({
        showMarkers: false
      });

      chart
        .interval()
        .position('year*sales')
        .color('type')
        .adjust('stack');

      chart.interaction('element-highlight-by-color');
      chart.interaction('element-link');

      chart.render();
    })
    */
  },
  methods: {
    async fetchAll() {
      this.fetchTotals()
    },
    async fetchTotals() {
      this.fetchingTotals = true
      const { data } = await window.axios.get(
        '/accounting/cashier-daily-totals',
        { params: this.filters }
      )
      this.totals = data
      this.fetchingTotals = false
    }
  }
}
</script>
